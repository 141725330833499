import up from 'unpoly';

const cn = (suffix = '') => `wp-block-berghs-time-edit-schedule${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

const zeroPad = (v) => {
    v = `${v}`;

    if (v.length === 1) {
        v = `0${v}`;
    }

    return v;
};

up.compiler(dcn('__time'), ($time) => {
    const updateCurrentTime = () => {
        const now = new Date();

        const formattedTime = [now.getHours(), now.getMinutes()]
            .map(zeroPad)
            .join(':');

        $time.textContent = formattedTime;
    };

    const handleAnimationFrame = () => {
        updateCurrentTime();

        requestAnimationFrame(handleAnimationFrame);
    };

    requestAnimationFrame(handleAnimationFrame);
});
