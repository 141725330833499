import up from 'unpoly';

import { $, $$ } from '../../../../utils/dom';

const dcn = (suffix = '') =>
    `.view-components-blocks-TestimonialsSlider${suffix}`;

up.compiler(dcn(), ($el) => {
    const $slides = $$(dcn('-slide'), $el);

    if ($slides.length <= 1) {
        return;
    }

    const $prevButton = $(dcn('-prevButton'), $el);
    const $nextButton = $(dcn('-nextButton'), $el);
    const $container = $(dcn('-container'), $el);

    let slideWidth;

    const goToSlide = (dI) => {
        const containerRect = $container.getBoundingClientRect();
        const containerCenter = containerRect.left + containerRect.width / 2;

        const oldI = $slides.findLastIndex(
            ($slide) => $slide.getBoundingClientRect().left < containerCenter,
        );

        let newI = oldI + dI;

        while (newI < 0) {
            newI += $slides.length;
        }
        while (newI >= $slides.length) {
            newI -= $slides.length;
        }

        const scrollLeft = newI * slideWidth;

        $container.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
        });
    };

    const handlePrevClick = () => {
        goToSlide(-1);
    };

    const handleNextClick = () => {
        goToSlide(1);
    };

    $prevButton.addEventListener('click', handlePrevClick);
    $nextButton.addEventListener('click', handleNextClick);

    const onResize = () => {
        slideWidth = $slides[0].getBoundingClientRect().width;
    };

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe($el);
    onResize();

    $prevButton.classList.remove('xl:hidden');
    $nextButton.classList.remove('xl:hidden');
});
