import up from 'unpoly';

const hrefPrefix = '#imbox-form-';

up.macro(`a[href^="${hrefPrefix}"]`, ($a) => {
    const href = $a.hash;

    $a.href = '#';

    const formId = href.slice(hrefPrefix.length);

    $a.addEventListener('click', (event) => {
        event.preventDefault();

        // eslint-disable-next-line no-underscore-dangle
        window._imbox.push(['openForm', formId]);
    });
});
