const $body = document.body;

let lockScrollY;

let isLocked = false;

function toggleBodyLock(lock) {
    if (lock === isLocked) {
        return;
    }

    if (lock) {
        lockScrollY = window.scrollY;

        $body.style.setProperty('width', `${$body.clientWidth}px`, 'important');
        $body.style.setProperty('position', 'fixed', 'important');
        $body.style.setProperty('top', `-${lockScrollY}px`, 'important');
    } else {
        $body.style.setProperty('position', '');
        $body.style.setProperty('top', '');
        $body.style.setProperty('width', '');

        window.scrollTo(0, lockScrollY);
    }

    isLocked = lock;
}

export default toggleBodyLock;
