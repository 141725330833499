import up from 'unpoly';

import lockBodyScroll from '../../../../utils/lockBodyScroll';
import { $ } from '../../../../utils/dom';

const dcn = (suffix = '') => `.view-components-site-SiteHeader${suffix}`;

up.compiler(dcn(), ($el) => {
    const $menu = $(dcn('-offCanvasMenu'), $el);
    const $openButton = $(dcn('-openOffCanvasMenuButton'), $el);
    const $closeButton = $(dcn('-closeOffCanvasMenuButton'), $el);

    const toggleIsOpen = (isOpen) => {
        lockBodyScroll(isOpen);

        $menu.classList.toggle('opacity-0', !isOpen);
        $menu.classList.toggle('pointer-events-none', !isOpen);
    };

    const handleOpenClick = (event) => {
        event.preventDefault();

        toggleIsOpen(true);
    };

    const handleCloseClick = (event) => {
        event.preventDefault();

        toggleIsOpen(false);
    };

    $openButton?.addEventListener('click', handleOpenClick);
    $closeButton?.addEventListener('click', handleCloseClick);
});
