export function encodeObjectToFormData(
    obj,
    formData = new FormData(),
    parentKey = '',
) {
    if (obj === null || obj === undefined) {
        formData.append(parentKey, '');
    } else if (typeof obj !== 'object') {
        formData.append(parentKey, obj.toString());
    } else {
        Object.entries(obj).forEach(([key, value]) => {
            const formKey = parentKey ? `${parentKey}[${key}]` : key; // Format key for nested objects/arrays

            if (value instanceof File) {
                formData.append(formKey, value);
            } else if (value instanceof Array) {
                // For arrays, check if we need to include the key in the form data
                value.forEach((val, i) => {
                    if (typeof val === 'object') {
                        encodeObjectToFormData(
                            val,
                            formData,
                            `${formKey}[${i}]`,
                        );
                    } else {
                        formData.append(`${formKey}[]`, val);
                    }
                });
            } else if (typeof value === 'object') {
                encodeObjectToFormData(value, formData, formKey);
            } else {
                formData.append(formKey, value);
            }
        });
    }

    return formData;
}

export function fetchPostJson(url, body) {
    return fetch(url, {
        method: 'POST',
        body: encodeObjectToFormData(body),
    }).then((response) => {
        if (!response.ok) {
            throw new Error(`${response.status}: ${response.statusText}`);
        }

        return response.json();
    });
}

export function adminPost(action, body) {
    return fetchPostJson(window.berghs.ajax_url, {
        action,
        ...body,
    });
}
