import up from 'unpoly';

import { $ } from '../../../../utils/dom';

const dcn = (suffix = '') => `.view-components-blocks-StickyCtaBanner${suffix}`;

up.compiler(dcn(), ($el) => {
    const $button = $(dcn('__button'), $el);

    $button?.addEventListener('click', (event) => {
        event.preventDefault();

        const clickEvent = $button.dataset.event;

        window.dispatchEvent(new Event(clickEvent));
    });
});
