import up from 'unpoly';

import { $ } from '../../../../utils/dom';

const dcn = (suffix = '') => `.view-components-layout-Expandable${suffix}`;

up.compiler(dcn(), ($el) => {
    const $anchor = $(dcn('-anchor'), $el);

    if (!$anchor) {
        return;
    }

    const $checkbox = $(dcn('-checkbox'), $el);

    let hash;

    const handleCheckboxChange = () => {
        if (!$checkbox.checked) {
            return;
        }

        const { id } = $anchor;

        $anchor.id = null;

        window.location.hash = `#${id}`;

        setTimeout(() => {
            $anchor.id = id;
        });
    };

    const handleHashChange = () => {
        if (hash === window.location.hash) {
            return;
        }

        hash = window.location.hash.slice(1);

        if (hash !== $anchor.id) {
            return;
        }

        $checkbox.checked = true;
    };

    $checkbox.addEventListener('change', handleCheckboxChange);
    window.addEventListener('hashchange', handleHashChange);

    handleHashChange();
});
