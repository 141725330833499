import up from 'unpoly';

up.macro('a[href^="#"]', ($el) => {
    $el.addEventListener('click', (e) => {
        const { hash } = $el;

        if (!hash || hash === '#') {
            return;
        }

        let $target;
        try {
            $target = document.querySelector($el.hash);
        } catch {
            // empty
        }

        if (!$target) {
            return;
        }

        e.preventDefault();

        const computedStyle = window.getComputedStyle(document.documentElement);
        const scrollPaddingTop =
            parseFloat(computedStyle.scrollPaddingTop) || 0;

        const targetScrollY =
            $target.getBoundingClientRect().top +
            window.scrollY -
            scrollPaddingTop;

        window.scrollTo({
            top: targetScrollY,
            behavior: 'smooth',
        });
    });
});
