export default async (
    data,
    { data: staticData = {}, endpoint, errorMessage, successMessage },
) => {
    const { adminPostUrl } = window.berghs;

    const responseHandlerUrl = `${adminPostUrl}?action=${encodeURIComponent(
        'berghs/pardot_form_handler_response',
    )}`;

    if (!endpoint) {
        throw new Error('No endpoint specified');
    }

    const iframe = document.createElement('iframe');
    iframe.className = 'hidden';
    document.body.appendChild(iframe);

    let iframeDocument = iframe.contentDocument;

    const form = iframeDocument.createElement('form');
    form.action = endpoint;
    form.method = 'POST';
    iframeDocument.body.appendChild(form);

    const inputs = {
        ...data,
        ...staticData,
        success_location: responseHandlerUrl,
        error_location: responseHandlerUrl,
    };

    Object.entries(inputs).forEach(([name, value]) => {
        const input = iframeDocument.createElement('input');
        input.name = name;
        input.value = value;
        form.appendChild(input);
    });

    try {
        await new Promise((resolve, reject) => {
            iframe.onload = resolve;
            iframe.onerror = (event) => reject(event.error);

            form.submit();
        });

        iframeDocument = iframe.contentDocument;

        if (!iframeDocument) {
            throw new Error('Response handler was not loaded.');
        }
    } catch (err) {
        const newErr = new Error(errorMessage);
        newErr.originalError = err;
        throw newErr;
    } finally {
        iframe.remove();
    }

    const responseEl = iframeDocument.querySelector(
        '.pardot-form-handler-response',
    );

    if (!responseEl) {
        throw new Error('Invalid response');
    }

    const response = JSON.parse(responseEl.dataset.response);

    if (response?.errors === 'true') {
        const err = new Error(errorMessage);
        err.originalError = new Error(response.errorMessage);
        throw err;
    }

    return successMessage;
};
