import up from 'unpoly';

import { $ } from '../../../../utils/dom';

const dcn = (suffix = '') =>
    `.view-components-misc-FeeCurrencyConverter${suffix}`;

up.compiler(dcn(), ($el) => {
    const $input = $(dcn('-input'), $el);
    const $output = $(dcn('-output'), $el);

    $input.addEventListener('input', () => {
        $output.textContent = `= ${$input.value}`;
    });
});
