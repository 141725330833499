import courseLead from './course-lead';
import pardot from './pardot';
import debug from './debug';

const handlers = {
    courseLead,
    debug,
    pardot,
};

export default function formSubmitHandler(args) {
    const {
        handler: handlerName,
        $form,
        $inputs,
        $submit,
        $message,
        options,
    } = args;

    const handler = handlers[handlerName];

    if (!handler) {
        throw new Error(`Invalid handler '${handlerName}'.`);
    }

    let isSubmitting = false;
    let message = null;

    const update = () => {
        $inputs.forEach(($i) => {
            $i.disabled = isSubmitting;
        });

        $submit.disabled = isSubmitting;

        $message.classList.toggle('hidden', !message);
        $message.innerHTML = message;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isSubmitting) {
            return;
        }

        // This does not support `name="foo[]"`
        const data = Object.fromEntries(new FormData($form));

        handler(data, options)
            .then((responseMessage) => {
                message = responseMessage;

                $form.reset();

                if (options.tracking && window.ga) {
                    const { category, action, label, value } = options.tracking;

                    window.ga('send', 'event', category, action, label, value);
                }
            })
            .catch((err) => {
                message = err.message;
            })
            .finally(() => {
                isSubmitting = false;

                update();
            });

        isSubmitting = true;

        update();
    };

    $form.addEventListener('submit', handleSubmit);
}
