import up from 'unpoly';

import { adminPost } from '../../../utils/ajax';
import { $, $$ } from '../../../utils/dom';

const cn = (suffix) => `view-templates-PageApplication${suffix || ''}`;
const dcn = (suffix) => `.${cn(suffix)}`;

up.compiler(dcn(), async ($form) => {
    const Dropzone = (await import('dropzone')).default;

    Dropzone.autoDiscover = false;

    const strings = JSON.parse($form.dataset.strings);

    // Elements

    const $idNumberInput = $form.querySelector(
        'input[name="applicant_has_id_number"]',
    );
    const $idNumberInputContainer = $form.querySelector(dcn('__IdNumberInput'));
    const $birthdayInputContainer = $form.querySelector(dcn('__BirthdayInput'));
    const $dropzoneInput = $form.querySelector(dcn('__DropzoneInput'));
    const $submitButton = $form.querySelector(dcn('__SubmitButton'));

    const fieldNames = [];
    const fields = {};
    let hasIdNumber = true;

    // Utils

    function scrollFieldIntoView(field) {
        const { $el } = field;

        const rect = $el.getBoundingClientRect();
        const absoluteElementTop = rect.top + window.pageYOffset;
        const middle = absoluteElementTop - window.innerHeight / 3;

        window.scrollTo({
            top: middle,
            behavior: 'smooth',
        });

        $el.focus();
    }

    function isValidIdNumber(value) {
        function isDate(year, month, day) {
            year = +year;
            month -= 1;
            day = +day;
            const tmpDate = new Date(year, month, day);
            if (
                getFullYear(tmpDate.getYear()) === getFullYear(year) &&
                month === tmpDate.getMonth() &&
                day === tmpDate.getDate()
            ) {
                return true;
            }
            return false;
        }

        function getFullYear(y) {
            let fullYear;
            if (y < 1000) {
                fullYear = parseInt(y, 10) + 1900;
            } else {
                fullYear = y;
            }
            return fullYear;
        }

        if (value.length < 10 || value.length > 13) {
            return false;
        }

        let checkSum = 0;

        if (value.length === 13) {
            if (value.indexOf('-') > -1) {
                value = value.replace('-', '');
            } else {
                return false;
            }
        }
        if (value.length === 11) {
            if (value.indexOf('-') > -1) {
                value = value.replace('-', '');
            } else {
                return false;
            }
        }
        if (value.length === 12) {
            value = value.substring(2);
        }

        if (
            !isDate(
                value.substring(0, 2),
                value.substring(2, 4),
                value.substring(4, 6),
            )
        ) {
            return false;
        }

        const numbers = value.match(
            /^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/,
        );

        if (numbers === null) {
            return false;
        }

        let n;

        for (let i = 1; i <= 10; i += 1) {
            n = parseInt(numbers[i], 10);

            if (i % 2 === 0) {
                checkSum += n;
            } else {
                checkSum += ((n * 2) % 9) + Math.floor(n / 9) * 9;
            }
        }

        if (checkSum % 10 === 0) {
            return true;
        }

        return false;
    }

    function isValidBirthDate(value) {
        if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
            return false;
        }

        try {
            const date = new Date(value);

            return date.toISOString().slice(0, 10) === value;
        } catch {
            return false;
        }
    }

    function isValidPhoneNumber(value) {
        return value.length > 6 && /^[0-9 -()+]+$/.test(value);
    }

    function isValidEmail(value) {
        // This is the regex that salesforce accepts
        return /^[a-zA-Z0-9._%+-/!#$%&'*=?^_`{|}~]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(
            value,
        );
    }

    function isValidUrl(value) {
        let url;

        try {
            url = new URL(value);
        } catch (err) {
            return false;
        }

        if (url.protocol !== 'http:' && url.protocol !== 'https:') {
            return false;
        }

        return true;
    }

    // Event Handlers

    function useBirthDate() {
        hasIdNumber = false;

        const birthdateField = fields.applicant_birthday;
        const idNumberField = fields.applicant_idnumber;

        idNumberField.required = false;
        idNumberField.$input.value = '';
        idNumberField.$input.removeAttribute('required');
        $idNumberInputContainer.classList.add('hidden');
        validateField(fields.applicant_idnumber);

        birthdateField.required = true;
        birthdateField.$input.setAttribute('required', '');
        $birthdayInputContainer.classList.remove('hidden');
    }

    function useIdNumber() {
        hasIdNumber = true;

        const birthdateField = fields.applicant_birthday;
        const idNumberField = fields.applicant_idnumber;

        birthdateField.required = false;
        birthdateField.$input.value = '';
        birthdateField.$input.required = false;
        $birthdayInputContainer.classList.add('hidden');
        validateField(birthdateField);

        idNumberField.required = true;
        idNumberField.$input.setAttribute('required', '');
        $idNumberInputContainer.classList.remove('hidden');
    }

    function onHasIdNumberchange(event) {
        if (event.currentTarget.checked) {
            useIdNumber();
        } else {
            useBirthDate();
        }
    }

    function showPriorLearningDialog(event) {
        event.preventDefault();

        const dialogContainer = $(dcn('__PriorLearningDialogContainer'));

        up.layer.open({
            document: dialogContainer.innerHTML,
            // mode: 'modal',
        });
    }

    // Inputs

    function inputFieldHasValue(field) {
        const { $input } = field;

        return $input.type === 'checkbox' ? $input.checked : !!$input.value;
    }

    function validateInputField(field) {
        const { $input } = field;
        const { name } = field;
        const hasValue = inputFieldHasValue(field);
        const { value } = $input;
        let errorMessageSuffix;
        let errorMessage;

        if (field.required && !hasValue) {
            errorMessageSuffix = '_required';
        } else if (
            field.requiredOr &&
            !hasValue &&
            !fieldHasValue(fields[field.requiredOr])
        ) {
            errorMessage =
                strings[`${field.name}_or_${field.requiredOr}_required`];
        }

        if (!errorMessage && !errorMessageSuffix && hasValue) {
            if (name === 'applicant_email') {
                if (!isValidEmail(value)) {
                    errorMessageSuffix = '_required';
                }
            } else if (name === 'applicant_confirm_email') {
                if (value !== fields.applicant_email.$input.value) {
                    errorMessageSuffix = '_mismatch';
                }
            } else if (name === 'applicant_idnumber' && hasIdNumber) {
                if (!isValidIdNumber(value)) {
                    errorMessageSuffix = '_required';
                }
            } else if (name === 'applicant_birthday' && !hasIdNumber) {
                if (!isValidBirthDate(value)) {
                    errorMessageSuffix = '_required';
                }
            } else if (name === 'applicant_phone') {
                if (!isValidPhoneNumber(value)) {
                    errorMessageSuffix = '_required';
                }
            } else if (field.name === 'applicant_url') {
                if (!isValidUrl(value)) {
                    errorMessageSuffix = '_required';
                }
            }
        }

        if (!errorMessage && errorMessageSuffix) {
            errorMessage = strings[name + errorMessageSuffix];
        }

        if (errorMessage) {
            field.$errorMessage.innerText = errorMessage;
        } else {
            field.$errorMessage.innerHTML = null;
        }

        $input.classList.toggle('is-valid', !!$input.value && !errorMessage);

        validateRelatedFields(field);

        return errorMessage;
    }

    function inputValidatingHandler(field) {
        return function () {
            validateField(field);
        };
    }

    function disableInputField(field) {
        field.$input.setAttribute('readonly', '');
    }
    function enableInputField(field) {
        field.$input.removeAttribute('readonly');
    }

    function initInputField($field) {
        const $input = $field.querySelector('input, select');
        const $errorMessage = $field.querySelector(dcn('__InputError'));

        const field = {
            type: 'input',
            name: $input.name,
            $el: $field,
            $input,
            required: $input.required,
            requiredOr: $input.dataset.requiredOr,
            $errorMessage,
        };

        if (
            field.name === 'applicant_email' ||
            field.name === 'applicant_confirm_email'
        ) {
            $input.addEventListener('paste', (event) => {
                event.preventDefault();
            });
        }

        if (
            $input.tagName === 'select' ||
            $input.type === 'checkbox' ||
            $input.type === 'radio'
        ) {
            $input.addEventListener('change', inputValidatingHandler(field));
        } else {
            $input.addEventListener('focusout', inputValidatingHandler(field));
        }

        fieldNames.push(field.name);
        fields[field.name] = field;
    }

    // Drop Zones

    function dropzoneFieldHasValue(field) {
        return field.dropzone.getAcceptedFiles().length > 0;
    }

    function validateDropzoneField(field) {
        const { dropzone } = field;
        let errorMessage;
        const hasFiles = dropzone.getAcceptedFiles().length > 0;

        if (field.required && !hasFiles) {
            errorMessage = strings[`${field.name}_required`];
        } else if (field.requiredOr) {
            if (!hasFiles && !fieldHasValue(fields[field.requiredOr])) {
                errorMessage =
                    strings[`${field.name}_or_${field.requiredOr}_required`];
            }
        }

        const isValid = !errorMessage && hasFiles;

        if (errorMessage) {
            field.$errorMessage.innerText = errorMessage;
        } else {
            field.$errorMessage.innerHTML = null;
        }

        field.$el.classList.toggle('is-valid', isValid);

        validateRelatedFields(field);

        return errorMessage;
    }

    function disableDropzoneField(field) {
        field.$el.classList.add(cn('.is-disabled'));
    }
    function enableDropzoneField(field) {
        field.$el.classList.remove(cn('.is-disabled'));
    }

    function onDropzoneValidateHandler(field) {
        return function () {
            setTimeout(() => {
                validateDropzoneField(field);
            }, 0);
        };
    }

    function removeAllDropzoneFiles() {
        getFields((field) => field.type === 'dropzone').forEach((field) => {
            field.dropzone.removeAllFiles(true);
        });
    }

    function uploadDropzoneFiles(reject, resolve) {
        const dropzoneFields = getFields((field) => field.type === 'dropzone');

        if (!dropzoneFields.length) {
            setTimeout(resolve, 0);
            return;
        }

        const fieldFiles = [];

        dropzoneFields.forEach((field) => {
            const queuedFiles = field.dropzone.getQueuedFiles();

            if (queuedFiles.length === 0) {
                return;
            }

            fieldFiles.push({
                field: field.name,
                files: queuedFiles.map((file) => ({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                })),
            });
        });

        if (fieldFiles.length === 0) {
            setTimeout(resolve, 0);
            return;
        }

        adminPost('applicationGetAmazonSignedUrls', {
            files: fieldFiles,
        })
            .then((response) => {
                const { fileURIs } = response.data;

                dropzoneFields.forEach((field) => {
                    if (field.dropzone.files.length === 0) {
                        return;
                    }

                    const { dropzone } = field;
                    const fieldFiles = fileURIs.find(
                        (fieldFileURIs) => fieldFileURIs.field === field.name,
                    ).files;

                    function cleanup() {
                        dropzoneFields.forEach((field) => {
                            field.dropzone
                                .off('processing')
                                .off('error')
                                .off('completed')
                                .off('queuecomplete');
                        });
                    }

                    dropzone.on('processing', function (file) {
                        this.options.url =
                            fieldFiles[dropzone.files.indexOf(file)].signed_url;
                    });

                    dropzone.on('error', () => {
                        cleanup();
                        reject();
                    });

                    dropzone.on('complete', () => {
                        field.dropzone.processQueue();
                    });

                    dropzone.on('queuecomplete', () => {
                        const allFieldsAreDone = dropzoneFields.every(
                            (field) =>
                                field.dropzone.getActiveFiles().length === 0,
                        );

                        if (!allFieldsAreDone) {
                            return;
                        }

                        const allFilesWereSuccessful = dropzoneFields.every(
                            (field) =>
                                field.dropzone
                                    .getAcceptedFiles()
                                    .every(
                                        (file) =>
                                            file.status !== Dropzone.ERROR,
                                    ),
                        );

                        cleanup();

                        if (!allFilesWereSuccessful) {
                            reject();
                            return;
                        }

                        $dropzoneInput.value = JSON.stringify(fileURIs);

                        resolve();
                    });
                });

                dropzoneFields.forEach((field) => {
                    field.dropzone.processQueue();
                });
            })
            .catch((error) => {
                reject(error);
            });
    }

    function initDropzoneField($field) {
        const $dropzone = $field.querySelector(dcn('__DropzoneInner'));
        const $errorMessage = $field.querySelector(dcn('__InputError'));

        const dropzone = new Dropzone($dropzone, {
            url: '/',
            parallelUploads: 1,
            method: 'put',
            paramName: 'file',
            addRemoveLinks: true,
            dictRemoveFile: strings.remove_file,
            maxFilesize: 2000,
            autoProcessQueue: false,
            sending(file, xhr) {
                const _send = xhr.send;
                xhr.send = function () {
                    _send.call(xhr, file);
                };
            },
        });

        const field = {
            type: 'dropzone',
            name: $field.dataset.name,
            $el: $field,
            dropzone,
            $errorMessage,
            required: $field.hasAttribute('required'),
            requiredOr: $field.dataset.requiredOr,
        };

        dropzone.on('addedfile', onDropzoneValidateHandler(field));
        dropzone.on('removedfile', onDropzoneValidateHandler(field));

        fieldNames.push(field.name);
        fields[field.name] = field;
    }

    // Fields

    function getFields(selector) {
        return fieldNames.map((name) => fields[name]).filter(selector);
    }

    function fieldHasValue(field) {
        if (field.type === 'input') {
            return inputFieldHasValue(field);
        }
        if (field.type === 'dropzone') {
            return dropzoneFieldHasValue(field);
        }
    }

    function validateField(field) {
        if (field.type === 'input') {
            return validateInputField(field);
        }
        if (field.type === 'dropzone') {
            return validateDropzoneField(field);
        }
    }

    function validateRelatedFields(field) {
        getFields((field2) => field2.requiredOr === field.name).forEach(
            (field2) => {
                validateField(field2);
            },
        );
    }

    function disableAllFields() {
        fieldNames.forEach((name) => {
            const field = fields[name];

            if (field.type === 'input') {
                disableInputField(field);
            } else if (field.type === 'dropzone') {
                disableDropzoneField(field);
            }
        });

        $submitButton.setAttribute('disabled', '');
    }
    function enableAllFields() {
        fieldNames.forEach((name) => {
            const field = fields[name];

            if (field.type === 'input') {
                enableInputField(field);
            } else if (field.type === 'dropzone') {
                enableDropzoneField(field);
            }
        });

        $submitButton.removeAttribute('disabled');
    }

    // Submit

    function onSubmitClick(event) {
        event.preventDefault();

        let firstInvalidField = null;

        fieldNames.forEach((name) => {
            const field = fields[name];

            if (validateField(field) && !firstInvalidField) {
                firstInvalidField = field;
            }
        });

        if (firstInvalidField) {
            scrollFieldIntoView(firstInvalidField);

            return;
        }

        if (!$form.checkValidity()) {
            return;
        }

        disableAllFields();

        uploadDropzoneFiles(
            () => {
                alert(strings.file_upload_error);

                // If only some files fail, this is easier than merging the successful files from the old upload
                removeAllDropzoneFiles();

                enableAllFields();
            },
            () => {
                $form.submit();
            },
        );
    }

    // Click Handlers

    $idNumberInput.addEventListener('change', onHasIdNumberchange);

    $form
        .querySelector(dcn('__PriorLearningLink'))
        ?.addEventListener('click', showPriorLearningDialog);

    // Fields

    $$([dcn('__InputContainer'), dcn('__Dropzone')].join(','), $form).forEach(
        ($field) => {
            if ($field.classList.contains(cn('__InputContainer'))) {
                initInputField($field);
            } else if ($field.classList.contains(cn('__Dropzone'))) {
                initDropzoneField($field);
            }
        },
    );

    // Submit

    // We use `click` so that we can cancel the default handler before validation is performed.
    $submitButton.addEventListener('click', onSubmitClick);
});
