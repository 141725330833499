import up from 'unpoly';

const cn = (suffix = '') => `wp-blocks-berghs-sticky-menu${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

up.compiler(dcn(), (_$el) => {
    document.documentElement.style.setProperty(
        '--site-header-margin-bottom',
        '3.75rem',
    );
});
