import up from 'unpoly';

const cn = (suffix = '') => `view-components-elements-Lottie${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

up.compiler(dcn(), (container) => {
    import('lottie-web').then((lottie) => {
        const { json } = container.dataset;
        const animationData = JSON.parse(json);

        container.innerHTML = '';

        lottie.loadAnimation({
            container,
            loop: true,
            autoplay: true,
            animationData,
        });
    });
});
