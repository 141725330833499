import up from 'unpoly';

const all = [];

const autosize = (el) => {
    if (el.offsetParent === null) {
        return;
    }

    const { pageXOffset } = window;
    const { pageYOffset } = window;

    el.style.height = null;

    const min = +window.getComputedStyle(el).height.replace(/px/, '');

    el.style.height = '0';

    const style = window.getComputedStyle(el);

    const borderTop = +style.borderTopWidth.replace(/px/, '');
    const borderBottom = +style.borderBottomWidth.replace(/px/, '');

    const scroll = el.scrollHeight;

    const auto = borderTop + scroll + borderBottom;

    let height;

    if (min >= auto) {
        height = null;
    } else {
        height = `${auto}px`;
    }

    el.style.height = height;

    window.scrollTo(pageXOffset, pageYOffset);
};

const onEvent = (event) => autosize(event.target);

const autosizeAll = () => {
    all.forEach(autosize);
};

const init = (el) => {
    all.push(el);

    el.classList.add('resize-none');

    el.addEventListener('input', onEvent);

    if (el.form) {
        el.form.addEventListener('reset', onEvent);
    }

    autosize(el);
};

const destroy = (el) => {
    el.removeEventListener('input', onEvent);

    if (el.form) {
        el.form.removeEventListener('reset', onEvent);
    }

    all.splice(all.indexOf(el), 1);
};

up.compiler('textarea.js-textarea-auto-height', (el) => {
    init(el);
    return () => destroy(el);
});

window.addEventListener('resize', autosizeAll);
