import up from 'unpoly';

up.compiler('.js-tooltip', ($el) => {
    import('@popperjs/core').then(({ createPopper }) => {
        const $tooltip = document.createElement('div');
        $tooltip.className = [
            'no-child-top-bottom-margin',
            'hidden',
            'max-w-sm',
            'p-4',
            'bg-orange-light',
            'shadow',
        ].join(' ');
        $tooltip.innerHTML = $el.dataset.tooltipContent;

        $el.after($tooltip);

        const popperInstance = createPopper($el, $tooltip, {
            placement: 'top',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });

        const show = () => {
            $tooltip.classList.remove('hidden');

            popperInstance.update();
        };

        const hide = () => {
            $tooltip.classList.add('hidden');
        };

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach((event) => {
            $el.addEventListener(event, show);
        });

        hideEvents.forEach((event) => {
            $el.addEventListener(event, hide);
        });
    });
});
