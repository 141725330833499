import up from 'unpoly';

import { $ } from '../../../utils/dom';

const dcn = (suffix = '') => `.wp-blocks-berghs-video-header${suffix}`;

up.compiler(dcn(), ($el) => {
    const $video = $(dcn('-video'), $el);
    const $muteButton = $(dcn('-muteButton'), $el);
    const $iconUnmuted = $(dcn('-iconUnmuted'), $el);
    const $iconMuted = $(dcn('-iconMuted'), $el);

    const handleMuteClick = (event) => {
        event.preventDefault();

        $video.muted = !$video.muted;
        $iconUnmuted.classList.toggle('hidden', $video.muted);
        $iconMuted.classList.toggle('hidden', !$video.muted);
    };

    $muteButton?.addEventListener('click', handleMuteClick);

    window.addEventListener('CookieConsentGiven', () => {
        $video.play();
    });
});
